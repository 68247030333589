<template>
  <div>
    <h1 class="uk-text-left">{{ $t('Pages.EditOrganization.Title') }}</h1>
    <hr />

    <div v-if="!loading" class="uk-grid-divider" uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-container uk-text-left">
          <span class="uk-text-large uk-text-left">{{$t('Pages.EditOrganization.OrgDetails')}} </span><span class="uk-text-danger" v-if="formChanged">{{$t('Generic.Labels.Unsaved')}}</span>

          <form class="uk-form-horizontal uk-text-left ukk-margin-small" @submit.prevent="updateOrgInfo();">

            <div class="uk-margin-medium">
              <label class="uk-form-label custom-label-margin" for="form-horizontal-text">Id</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="form-horizontal-text" type="text" placeholder="Id" :value="form.org.id" disabled>
              </div>
            </div>

            <div class="uk-margin-small">
                <label class="uk-form-label" for="form-horizontal-text">
                  {{$t("Generic.Labels.Name")}}
                  <span class="uk-text-danger">*</span>
                </label>              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" :class="{'invalid-border': !focused.name && !(form.org.name && form.org.name.length > 2) }" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Name')" v-model.trim="form.org.name" @focus="focused.name = true" @blur="focused.name = false">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!focused.name && !(form.org.name && form.org.name.length > 2)" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!focused.name && !(form.org.name && form.org.name.length > 2)" >{{$t("Generic.Errors.InvalidInput")}}</span>
                </div>
              </div>
            </div>

            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">
                {{$t('Generic.Labels.Address')}}
                <span class="uk-text-danger">*</span>
              </label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <textarea class="uk-textarea" :class="{'invalid-border': !focused.address && !(form.org.address && form.org.address.length > 2) }" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Address')" v-model.trim="form.org.address" @focus="focused.address = true" @blur="focused.address = false"></textarea>
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!focused.address && !(form.org.address && form.org.address.length > 2)" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!focused.address && !(form.org.address && form.org.address.length > 2)" >{{$t("Generic.Errors.InvalidInput")}}</span>
                </div>
              </div>
            </div>

            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{$t('Generic.Labels.Phone')}}</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" :class="{'invalid-border': !isPhoneValid }" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Address')" v-model.trim="form.org.phone">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!isPhoneValid" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!isPhoneValid" >{{$t("Generic.Errors.InvalidInput")}}</span>
                </div>
              </div>
            </div>

            <div class="uk-margin-small" v-if="form.org.site">
              <label class="uk-form-label custom-label-margin" for="form-horizontal-text">{{$t("Generic.Labels.Site")}}</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Site')" v-model="form.org.site" disabled>
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!isWebsiteValid" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right" v-if="!isWebsiteValid" >{{$t("Generic.Errors.InvalidInput")}}</span>
                </div>
              </div>
            </div>
            <hr>

            <br>
            <div class="uk-margin-small">
              <div uk-grid class="">

                <div  class="uk-width-1-2@l">
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.Status")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="isOrgActive" @click="toggleOrgStatus()" />
                  </div>
                </div>

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.AdsSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.ads" @click="form.org.options.featureSupport.ads = !form.org.options.featureSupport.ads" />
                  </div>
                </div>


                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.AnalyticsSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.analytics" @click="form.org.options.featureSupport.analytics = !form.org.options.featureSupport.analytics" />
                  </div>
                </div>

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.SpatialAudioSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.spatialAudio" @click="form.org.options.featureSupport.spatialAudio = !form.org.options.featureSupport.spatialAudio" />
                  </div>
                </div>

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.InteractiveVideoSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.interactiveVideos" @click="form.org.options.featureSupport.interactiveVideos = !form.org.options.featureSupport.interactiveVideos" />
                  </div>
                </div>

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.ClearVRSupport")}}</label>
                  <div class="uk-form-controls" :uk-tooltip="$t('Pages.EditOrganization.ClearVRConfigDescription')" >
                    <vue-toggles width="60" :value="this.form.org.options && this.form.org.options.featureSupport.clearVR"  class="uk-disabled"/>
                  </div>
                </div>

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.TranscodingSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.transcoding" @click="form.org.options.featureSupport.transcoding = !form.org.options.featureSupport.transcoding" />
                  </div>
                </div>                

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.ApplicationFencingSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.appFencing" @click="form.org.options.featureSupport.appFencing = !form.org.options.featureSupport.appFencing" />
                  </div>
                </div>                

                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.PushNotificationSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.pushNotifications" @click="form.org.options.featureSupport.pushNotifications = !form.org.options.featureSupport.pushNotifications" />
                  </div>
                </div>

                <!-- Ar -->
                <div  class="uk-width-1-2@l">                  
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.ARSupport")}}</label>
                  <div class="uk-form-controls">
                    <vue-toggles width="60" :value="form.org.options && form.org.options.featureSupport.ar" @click="form.org.options.featureSupport.ar = !form.org.options.featureSupport.ar" />
                  </div>
                </div>
              </div> 
              <br>
            </div>

            <hr>

            <h3> {{$t("Pages.EditOrganization.ClearVRConfig")}} </h3>
            <div class="uk-text-muted uk-margin-small">{{$t("Pages.EditOrganization.ClearVRConfigDescription")}}</div>

            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.APIAuthToken")}}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="form-horizontal-text" type="text" @blur="setClearVRConfigStatus()" :placeholder="$t('Generic.Labels.Token')" v-model="form.org.clearVRConfig.apiAuthToken">
              </div>
            </div>

            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.EditOrganization.CustomerId")}}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="form-horizontal-text" @blur="setClearVRConfigStatus()" type="text" :placeholder="$t('Pages.EditOrganization.CustomerId')" v-model="form.org.clearVRConfig.customerId">
              </div>
            </div>

            <hr />
            <span class="uk-text-danger uk-align-center uk-text-center" v-if="!isValid">{{$t('Generic.Errors.PageHasErrors')}}
              <span class="uk-margin-small-right uk-padding-remove " uk-icon="icon: warning"></span>
            </span>
            <button v-if="!saving" class="uk-button uk-button-primary uk-align-center" @click.prevent="updateOrgInfo();">{{ $t('Actions.Save') }}</button>
            <button v-else class="uk-button uk-button-primary uk-align-center" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          </form>
        </div>
      </div>
  
      <div class="uk-width-1-3">
        <h3>{{$t("Generic.Labels.Logo")}}</h3>
        <div class="uk-container">
          <ImageOrAbbrevation class="uk-margin-bottom" :src="form.org.logo && form.org.logo.url" :alt="form.org && form.org.name"  height="250px" width="250px" imageClass="uk-border-rounded"/>
          <button class="uk-button uk-button-primary uk-margin-small-right uk-margin-small-left uk-width-small" uk-toggle="target: #image-upload-modal" >{{$t("Actions.Change")}}</button>

          <ImageUploadModal @start="uploading=true" @complete="onUploadComplete" :subtitle="$t('Generic.Labels.Image1x1')" />
          
        </div>
      </div>
    </div>
    <span v-else uk-spinner="ratio: 1">   </span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/components/util/Notification';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import ImageUploadModal from '@/components/modals/ImageUploadModal';

export default {
  name: 'OrganizationEdit',
  components: {
    ImageOrAbbrevation,
    ImageUploadModal
  },
  data() {
    return {
      orgActveStatuses: ["new", "active"],
      saving: false,
      //   : ["suspended", "pending"],
      detectChange: false,
      formChanged: false,
      formSignature: '',
      uploading: false,
      isValid: true,
      focused: {
        name: '',
        address: ''
      },
      form : {
        logo: '',
        errors: {},
        org: {
          id: '',
          name: '',
          address: '',
          phone: '',
          site: '',
          active: false,
          options: {
            featureSupport: {}
          },
          logo: {},
          status: '',
          clearVRConfig: {}
        }
      }
    }
  },
  computed: {
    ...mapState({
      org: state => state.venom.org || {}
    }),
    isOrgActive() {
      return this.orgActveStatuses.includes(this.form.org.status?.toLowerCase());
    },
    isPhoneValid () {
      if (this.form.org.phone?.length && this.form.org.phone.length < 10) {       
        return false;
      } else {
        return true;
      }
    },
    loading () {
      return !this.form?.org?.id
    },
    isWebsiteValid () {
      if (!this.form.org.site) return true // Nullable for backward compatibility
      const expression =/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig;
      const regex = new RegExp(expression);
      if (this.form.org.site.match(regex)) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    org () {
      const org = this.org
      this.form.org = Object.assign(this.form.org, {
        id: org.id,
        name: org.name,
        address: org.address,
        phone: org.phone,
        site: org.site,
        status: org.status,
        options: {
          featureSupport: org.options?.featureSupport
        },
        logo: {
          url: org.logo?.url
        },
        clearVRConfig: org.clearVRConfig
      })
      if (!this.isValid) {
        this.isFormValid()
      }
      this.$nextTick(() => {
        this.formSignature = this.getSignature()
        this.formChanged = false
        this.detectChange = true
      })
    },
    form: {
      handler () {
        if (this.detectChange && this.formSignature) {
          this.$nextTick(() => {
            const newSignature = this.getSignature()
            if (newSignature !== this.formSignature) {
              this.formChanged = true
            } else {
              this.formChanged = false
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getSignature () {
      const signObj = Object.assign({}, this.form.org)
      delete signObj.logo
      return JSON.stringify(signObj)
    },
    async updateOrgInfo () {
      this.setClearVRConfigStatus();
      const valid = this.isFormValid();
      if (!valid) {
        this.isValid = false
        return
      }
      this.saving = true
      delete this.form.org.options.featureSupport.cms2;
      const params = {
        organization: this.form.org.id,
        name: this.form.org.name,
        address: this.form.org.address,
        status: this.form.org.status,
        options: this.form.org.options.featureSupport,
        // TODO: Need to update API to support URL update
        // site: this.form.org.site,
        clearVRConfig: this.form.org.clearVRConfig
      }
      if (this.isPhoneValid) {
        params.phone = this.form.org.phone;
      }
      const response = await this.$store.dispatch('editOrg', params).catch(err => {
        Notification.showNotification('Organization update failed', err && err?.message, 'error');
      })
      this.saving = false
      if (response.id){
        Notification.showNotification(this.$t('Pages.EditOrganization.OrgUpdateSuccess'), this.$t('Pages.EditOrganization.OrgUpdateSuccessDesc') );
        process.nextTick(() => {
          this.formSignature = this.getSignature()
          this.formChanged = false
          this.detectChange = true
        })
      } else {
        Notification.showNotification(this.$t('Pages.EditOrganization.OrgUpdateFailed'), response, 'error');
      }
    },
    isFormValid () {
      const mandatoryFields = ['name', 'address'];
      for (let field of mandatoryFields) {
        if (!this.form.org[field] || !(this.form.org[field].length > 2)) {
          return false;
        }
      }
      
      // ToDo: To uncomment when website validation is in place
      // if (!this.isWebsiteValid) {
      //   return false
      // }
      if (this.isPhoneValid) this.isValid = true
      return this.isPhoneValid;
    },
    toggleOrgStatus () {
      this.form.org.status = this.isOrgActive ? this.form.org.status = "SUSPENDED" : this.form.org.status = "ACTIVE";
    },
    setClearVRConfigStatus () {
      if (this.form.org.clearVRConfig.apiAuthToken && this.form.org.clearVRConfig.customerId) {
        this.form.org.options.featureSupport.clearVR = true;
      } else {
        this.form.org.options.featureSupport.clearVR = false;
      }
    },
    async onUploadComplete (file) {
      window.UIkit.modal('#image-upload-modal').hide();
      const logoUpdateParams = {
        logo: {
          key: file.path
        },
        organization: this.form.org.id,
        previewUrl: file.previewUrl
      }
      const response = await this.$store.dispatch('editOrg', logoUpdateParams);
      if (response.id) {
        this.form.org.logo.url = file.previewUrl;
        Notification.showNotification(this.$t('Pages.EditOrganization.OrgUpdateSuccess'), this.$t('Pages.EditOrganization.OrgUpdateSuccessDesc') );
      }
    }
  },
  created() {
    const org = this.org
    this.form.org = Object.assign(this.form.org, {
      id: org.id,
      name: org.name,
      address: org.address,
      phone: org.phone,
      site: org.site,
      status: org.status,
      options: {
        featureSupport: org.options?.featureSupport
      },
      logo: {
        url: org.logo?.url
      },
      clearVRConfig: org.clearVRConfig
    })
    window.scrollTo(0,0); 
  }
}
</script>

<style scoped>
  .custom-label-margin {
    margin-top: 10px;
  }
</style>